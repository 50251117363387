<template>
    <flip-countdown v-if="deadTime" :deadline="deadTime" @timeElapsed="finish" :showDays="false"></flip-countdown>
</template>

<script>
 import FlipCountdown from 'vue2-flip-countdown'
 import moment from 'moment'
export default {
    components: { FlipCountdown },
    data(){
        return {
            deadTime: null
        }
    },
    async created(){
        this.deadTime = moment(new Date()).add(10,'seconds').format("YYYY-MM-DD HH:mm:ss").toString()
        console.log(this.deadTime)
    },
    methods:{
        finish(){
            console.log('Time finish')
        }
    }
}
</script>

<style>

</style>